import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firestoreAction } from "vuexfire";
import axios from "@/http/axios";

const getPortfolioDataDefaultState = () => {
  return {
    portfolioCumulativeReturns: undefined,
    benchmarkCumulativeReturns: undefined,
    performanceStats: undefined,
    outperformance: undefined,
    drawdowns: undefined,
    yearToDateStartDate: undefined,
  };
};

const state = {
  portfolioData: getPortfolioDataDefaultState(),
  portfolios: null,
  predefinedPortfolios: undefined,
};

const getters = {
  getPortfolios: (state) => {
    // Here we want to also return the predefined portfolios,
    // but if any is undefined, it means we have an issue with firestore.
    if (state.portfolios === undefined || state.predefinedPortfolios === null) {
      return null;
    }

    if (state.portfolios == null) {
      return state.predefinedPortfolios;
    }

    if (state.predefinedPortfolios == undefined) {
      return state.portfolios;
    }

    // We first return the predefined portfolios so that they appear first.
    return state.predefinedPortfolios.concat(state.portfolios);
  },
  getPortfolio: (_, getters) => (portfolioId) => {
    // Get both user-defined portfolios and predefined portfolios.
    const portfolios = getters.getPortfolios;
    if (portfolios == null) {
      return null;
    }

    return portfolios.find((portfolio) => portfolio.id === portfolioId);
  },
  getPortfolioCumulativeReturns: (state) => {
    return state.portfolioData.portfolioCumulativeReturns;
  },
  getBenchmarkCumulativeReturns: (state) => {
    return state.portfolioData.benchmarkCumulativeReturns;
  },
  getPerformanceStats: (state) => {
    return state.portfolioData.performanceStats;
  },
  getOutperformance: (state) => {
    return state.portfolioData.outperformance;
  },
  getDrawdowns: (state) => {
    return state.portfolioData.drawdowns;
  },
  getYearToDateStartDate: (state) => {
    return state.portfolioData.yearToDateStartDate;
  },
};

const mutations = {
  RESET_PORTFOLIO_DATA_STATE(state) {
    Object.assign(state.portfolioData, getPortfolioDataDefaultState());
  },
  FETCH_PORTFOLIO_CUMULATIVE_RETURNS_SUCCESS: (state, { data }) => {
    state.portfolioData.portfolioCumulativeReturns = data;
  },
  FETCH_BENCHMARK_CUMULATIVE_RETURNS_SUCCESS: (state, { data }) => {
    state.portfolioData.benchmarkCumulativeReturns = data;
  },
  FETCH_PERFORMANCE_STATS_SUCCESS: (state, { data }) => {
    state.portfolioData.performanceStats = data;
  },
  FETCH_OUTPERFORMANCE_SUCCESS: (state, { data }) => {
    state.portfolioData.outperformance = data;
  },
  FETCH_DRAWDOWNS_SUCCESS: (state, { data }) => {
    state.portfolioData.drawdowns = data;
  },
  FETCH_YEAR_TO_DATE_START_DATE_SUCCESS: (state, { data }) => {
    state.portfolioData.yearToDateStartDate = data;
  },
  FETCH_PREDEFINED_PORTFOLIOS_SUCCESS: (state, { predefinedPortfolios }) => {
    state.predefinedPortfolios = predefinedPortfolios;
  },
  FETCH_PORTFOLIOS_CONFIG_FAILURE: (state) => {
    state.portfolios = undefined;
  },
  FETCH_PREDEFINED_PORTFOLIOS_CONFIG_FAILURE: (state) => {
    state.predefinedPortfolios = null;
  },
};

const actions = {
  resetPortfolioDataState({ commit }) {
    commit("RESET_PORTFOLIO_DATA_STATE");
  },
  bindPortfoliosRef: firestoreAction(({ bindFirestoreRef, commit }) => {
    // Bind portfolios for the current user, but also make sure to get
    // the predefined portfolios.
    const getPortfoliosPromise = () => {
      const currentUser = firebase.auth().currentUser;
      if (!currentUser) {
        return Promise.resolve();
      }

      return bindFirestoreRef(
        "portfolios",
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .collection("portfolios")
      ).catch((error) => {
        // We need to rethrow the error if we hope to catch
        // it in sentry.
        // Note that we only do this if the user is still set,
        // as the most likely case is that we tried to log in the
        // user, it succeeded but we needed to sign out him, and
        // thus the binding promise above fail with a "Missing permission".
        // as it already started to get the link with Firestore
        // while we signed out the user.
        if (firebase.auth().currentUser) {
          commit("FETCH_PORTFOLIOS_CONFIG_FAILURE");
          throw error;
        }
      });
    };

    const predefinedPortfoliosPromise = Promise.resolve();
    /*
    // TODO: Activate that once we have predefined portfolios.
    const predefinedPortfoliosPromise = firebase
      .firestore()
      .collection("predefinedPortfolios")
      .get()
      .then((querySnapshot) => {
        const predefinedPortfolios = [];
        querySnapshot.forEach((predefinedPortfolioDoc) => {
          predefinedPortfolios.push(predefinedPortfolioDoc.data());
        });
        commit("FETCH_PREDEFINED_PORTFOLIOS_SUCCESS", {
          predefinedPortfolios,
        });
      }).catch(() => {
        commit("FETCH_PREDEFINED_CUSTOM_INDICATOR_CONFIG_FAILURE");
      });
    */
    return Promise.all([getPortfoliosPromise(), predefinedPortfoliosPromise]);
  }),
  updatePortfolio: firestoreAction((_, { portfolioId, newPortfolio }) => {
    const currentUser = firebase.auth().currentUser;
    return firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("portfolios")
      .doc(portfolioId)
      .set(newPortfolio, { merge: true });
  }),
  deletePortfolio: firestoreAction((_, { portfolioId }) => {
    const currentUser = firebase.auth().currentUser;
    return firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("portfolios")
      .doc(portfolioId)
      .delete();
  }),
  runBacktest(
    { commit },
    {
      portfolioId,
      benchmarkId,
      benchmarkExchangeMic,
      period,
      currency,
      rebalancingFrequency,
      txCost,
    }
  ) {
    const params = {
      currency: currency,
      rebalancing_frequency: rebalancingFrequency,
      benchmark_id: benchmarkId,
      benchmark_exchange_mic: benchmarkExchangeMic,
      tx_cost: txCost,
    };
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    return axios
      .post(`/api/portfolios/${portfolioId}/backtest`, params)
      .then((response) => {
        commit("FETCH_PORTFOLIO_CUMULATIVE_RETURNS_SUCCESS", {
          data: response.data["cum_returns_portfolio"],
        });
        commit("FETCH_BENCHMARK_CUMULATIVE_RETURNS_SUCCESS", {
          data: response.data["cum_returns_benchmark"],
        });
        commit("FETCH_PERFORMANCE_STATS_SUCCESS", {
          data: response.data["perf_stats"],
        });
        commit("FETCH_OUTPERFORMANCE_SUCCESS", {
          data: response.data["outperformance"],
        });
        commit("FETCH_DRAWDOWNS_SUCCESS", {
          data: response.data["drawdowns"],
        });
        commit("FETCH_YEAR_TO_DATE_START_DATE_SUCCESS", {
          data: response.data["ytd_start_date"],
        });
      })
      .catch((error) => {
        commit("FETCH_PORTFOLIO_CUMULATIVE_RETURNS_SUCCESS", {
          data: null,
        });
        commit("FETCH_BENCHMARK_CUMULATIVE_RETURNS_SUCCESS", {
          data: null,
        });
        commit("FETCH_PERFORMANCE_STATS_SUCCESS", {
          data: null,
        });
        commit("FETCH_OUTPERFORMANCE_SUCCESS", {
          data: null,
        });
        commit("FETCH_DRAWDOWNS_SUCCESS", {
          data: null,
        });
        commit("FETCH_YEAR_TO_DATE_START_DATE_SUCCESS", {
          data: null,
        });

        // We rethrow the error for sentry to catch it.
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
