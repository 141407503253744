import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firestoreAction } from "vuexfire";
import axios from "@/http/axios";

const getDefaultState = () => {
  return {
    indicator: undefined,
    indicatorCurrentLevel: undefined,
    cumulativeReturnsWithExposure: undefined,
    outperformanceData: undefined,
    drawdownsData: undefined,
    performanceData: undefined,
    performanceDataFactsheet: undefined,
    biggestDrawdownsData: undefined,
    yearToDateStartDate: undefined,
    downloadableIndicator: undefined,
    downloadableEndOfDayExposure: undefined,
  };
};
// initial state
// Note that indicatorConfig works the other way for error detection,
// null being the base state and undefined the error state.
const state = {
  indicatorData: getDefaultState(),
  indicatorConfig: null,
  notifications: [],
};

const getters = {
  getIndicator: (state) => {
    return state.indicatorData.indicator;
  },
  getIndicatorCurrentLevel: (state) => {
    return state.indicatorData.indicatorCurrentLevel;
  },
  getCumulativeReturnsWithExposure: (state) => {
    return state.indicatorData.cumulativeReturnsWithExposure;
  },
  getOutperformanceData: (state) => {
    return state.indicatorData.outperformanceData;
  },
  getDrawdownsData: (state) => {
    return state.indicatorData.drawdownsData;
  },
  getBiggestDrawdownsData: (state) => {
    return state.indicatorData.biggestDrawdownsData;
  },
  getPerformanceStats: (state) => {
    return state.indicatorData.performanceData;
  },
  getYearToDateStartDate: (state) => {
    return state.indicatorData.yearToDateStartDate;
  },
  getDownloadableIndicator: (state) => {
    return state.indicatorData.downloadableIndicator;
  },
  getDownloadableEndOfDayExposure: (state) => {
    return state.indicatorData.downloadableEndOfDayExposure;
  },
  getPerformanceStatsFactsheet: (state) => {
    return state.indicatorData.performanceDataFactsheet;
  },
  getIndicatorConfig: (state) => {
    return state.indicatorConfig;
  },
  getNotifications: (state) => {
    return state.notifications;
  },
};

const mutations = {
  RESET_INDICATOR_STATE(state) {
    state.indicatorData = getDefaultState();
  },
  FETCH_INDICATOR_SUCCESS: (state, { data }) => {
    state.indicatorData.indicator = data;
  },
  FETCH_INDICATOR_CURRENT_LEVEL_SUCCESS: (state, { data }) => {
    state.indicatorData.indicatorCurrentLevel = data;
  },
  FETCH_CUMULATIVE_RETURNS_WITH_EXPOSURE_SUCCESS: (state, { data }) => {
    state.indicatorData.cumulativeReturnsWithExposure = data;
  },
  FETCH_OUTPERFORMANCE_DATA_SUCCESS: (state, { data }) => {
    state.indicatorData.outperformanceData = data;
  },
  FETCH_DRAWDOWNS_DATA_SUCCESS: (state, { data }) => {
    state.indicatorData.drawdownsData = data;
  },
  FETCH_BIGGEST_DRAWDOWNS_DATA_SUCCESS: (state, { data }) => {
    state.indicatorData.biggestDrawdownsData = data;
  },
  FETCH_PERFORMANCE_DATA_SUCCESS: (state, { data }) => {
    state.indicatorData.performanceData = data;
  },

  FETCH_YEAR_TO_DATE_START_DATE_SUCCESS: (state, { data }) => {
    state.indicatorData.yearToDateStartDate = data;
  },
  FETCH_DOWNLOADABLE_INDICATOR_SUCCESS: (state, { data }) => {
    data?.forEach((el) => (el["date"] = el["date"].slice(0, 10)));
    state.indicatorData.downloadableIndicator = data;
  },
  FETCH_DOWNLOADABLE_END_OF_DAY_EXPOSURE_SUCCESS: (state, { data }) => {
    data?.forEach((el) => (el["date"] = el["date"].slice(0, 10)));
    state.indicatorData.downloadableEndOfDayExposure = data;
  },
  FETCH_PERFORMANCE_DATA_FACTSHEET_SUCCESS: (state, { data }) => {
    state.indicatorData.performanceDataFactsheet = data;
  },
  FETCH_INDICATOR_CONFIG_FAILURE: (state) => {
    state.indicatorConfig = undefined;
  },
};

const actions = {
  resetIndicatorDataState({ commit }) {
    commit("RESET_INDICATOR_STATE");
  },
  runBacktest(
    { commit },
    {
      indicatorId,
      benchmarkId,
      benchmarkExchangeMic,
      period,
      currency,
      minInUnderlying,
      maxInUnderlying,
      underlyingInBenchmark,
      statIds,
      underlyingType,
      rebalancingFrequency,
      rebalancingThreshold,
      txCost,
    }
  ) {
    const params = {
      currency: currency,
      benchmark_id: benchmarkId,
      benchmark_exchange_mic: benchmarkExchangeMic,
    };
    if (minInUnderlying != null) {
      params["min_in_underlying"] = minInUnderlying;
    }
    if (maxInUnderlying != null) {
      params["max_in_underlying"] = maxInUnderlying;
    }
    if (underlyingInBenchmark != null) {
      params["underlying_in_bm"] = underlyingInBenchmark;
    }
    if (period) {
      params["period_start_date"] = period.start;
      params["period_end_date"] = period.end;
    }
    if (statIds) {
      params["stat_ids"] = statIds;
    }
    if (underlyingType) {
      params["underlying_type"] = underlyingType;
    }
    if (rebalancingFrequency !== undefined) {
      params["rebalancing_frequency"] = rebalancingFrequency;
    }
    if (rebalancingThreshold !== undefined) {
      params["rebalancing_threshold"] = rebalancingThreshold;
    }
    if (txCost != null) {
      params["tx_cost"] = txCost;
    }
    return axios
      .post(`/api/indicators/${indicatorId}/backtest`, params)
      .then((response) => {
        commit("FETCH_INDICATOR_SUCCESS", {
          data: response.data["indicator"],
        });
        commit("FETCH_INDICATOR_CURRENT_LEVEL_SUCCESS", {
          data: response.data["indicator_current_level"],
        });
        commit("FETCH_CUMULATIVE_RETURNS_WITH_EXPOSURE_SUCCESS", {
          data: response.data["cumulative_returns_with_exposure"],
        });
        commit("FETCH_OUTPERFORMANCE_DATA_SUCCESS", {
          data: response.data["outperformance_data"],
        });
        commit("FETCH_DRAWDOWNS_DATA_SUCCESS", {
          data: response.data["drawdowns_data"],
        });
        commit("FETCH_BIGGEST_DRAWDOWNS_DATA_SUCCESS", {
          data: response.data["biggest_drawdowns_data"],
        });
        commit("FETCH_PERFORMANCE_DATA_SUCCESS", {
          data: response.data["performance_data"],
        });
        commit("FETCH_YEAR_TO_DATE_START_DATE_SUCCESS", {
          data: response.data["ytd_start_date"],
        });
        commit("FETCH_DOWNLOADABLE_INDICATOR_SUCCESS", {
          data: response.data["downloadable_indicator"],
        });
        commit("FETCH_DOWNLOADABLE_END_OF_DAY_EXPOSURE_SUCCESS", {
          data: response.data["downloadable_end_of_day_exposure"],
        });
        commit("FETCH_PERFORMANCE_DATA_FACTSHEET_SUCCESS", {
          data: response.data["performance_data_factsheet"],
        });
      })
      .catch((error) => {
        commit("FETCH_INDICATOR_SUCCESS", {
          data: null,
        });
        commit("FETCH_INDICATOR_CURRENT_LEVEL_SUCCESS", {
          data: null,
        });
        commit("FETCH_CUMULATIVE_RETURNS_WITH_EXPOSURE_SUCCESS", {
          data: null,
        });
        commit("FETCH_OUTPERFORMANCE_DATA_SUCCESS", {
          data: null,
        });
        commit("FETCH_DRAWDOWNS_DATA_SUCCESS", {
          data: null,
        });
        commit("FETCH_BIGGEST_DRAWDOWNS_DATA_SUCCESS", {
          data: null,
        });
        commit("FETCH_PERFORMANCE_DATA_SUCCESS", {
          data: null,
        });
        commit("FETCH_YEAR_TO_DATE_START_DATE_SUCCESS", {
          data: null,
        });
        commit("FETCH_DOWNLOADABLE_INDICATOR_SUCCESS", {
          data: null,
        });
        commit("FETCH_DOWNLOADABLE_END_OF_DAY_EXPOSURE_SUCCESS", {
          data: null,
        });
        commit("FETCH_PERFORMANCE_DATA_FACTSHEET_SUCCESS", {
          data: null,
        });

        // We rethrow the error for sentry to catch it.
        throw error;
      });
  },
  bindIndicatorConfig: firestoreAction(
    ({ bindFirestoreRef, commit }, { indicatorId }) => {
      return bindFirestoreRef(
        "indicatorConfig",
        firebase.firestore().collection("indicators").doc(indicatorId)
      ).catch((error) => {
        commit("FETCH_INDICATOR_CONFIG_FAILURE");
        commit("FETCH_CUSTOM_INDICATOR_CONFIG_FAILURE");
        // We need to rethrow the error if we hope to catch
        // it in sentry.
        throw error;
      });
    }
  ),
  updateIndicatorConfig: firestoreAction(
    (_, { indicatorId, newIndicatorConfig }) => {
      return firebase
        .firestore()
        .collection("indicators")
        .doc(indicatorId)
        .set(newIndicatorConfig, { merge: true });
    }
  ),
  bindNotifications: firestoreAction(
    ({ bindFirestoreRef }, { indicatorId }) => {
      const currentUser = firebase.auth().currentUser;
      return bindFirestoreRef(
        "notifications",
        firebase
          .firestore()
          .collection("users")
          .doc(currentUser.uid)
          .collection("notifications")
          .doc(indicatorId)
          .collection("userDefined")
      );
    }
  ),
  addNotification: firestoreAction((_, { indicatorId, newNotification }) => {
    const currentUser = firebase.auth().currentUser;
    // Whatever the situation, we need to create the indicatorId document
    // otherwise when we will iterate on every user, we won't see indicator
    // that have been created through the "userDefined" collection.
    // Check: https://firebase.google.com/docs/firestore/using-console?authuser=0&hl=en#non-existent_ancestor_documents
    // Note that we don't care about waiting for the result here,
    // the save of the next operation doesn't need this one to achieve,
    // and we only need to eventually create the indicatorId document.
    firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("notifications")
      .doc(indicatorId)
      .set({}, { merge: true });

    // Here we get a new id first, that we will attach to our newNotification
    // object before saving it in firestore.
    const newNotificationRef = firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("notifications")
      .doc(indicatorId)
      .collection("userDefined")
      .doc();

    newNotification["notificationId"] = newNotificationRef.id;

    return newNotificationRef.set(newNotification);
  }),
  updateNotification: firestoreAction(
    (_, { indicatorId, updatedNotification }) => {
      const currentUser = firebase.auth().currentUser;
      return firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .collection("notifications")
        .doc(indicatorId)
        .collection("userDefined")
        .doc(updatedNotification["notificationId"])
        .set(updatedNotification, { merge: true });
    }
  ),
  deleteNotification: firestoreAction((_, { indicatorId, notification }) => {
    const currentUser = firebase.auth().currentUser;
    return firebase
      .firestore()
      .collection("users")
      .doc(currentUser.uid)
      .collection("notifications")
      .doc(indicatorId)
      .collection("userDefined")
      .doc(notification["notificationId"])
      .delete();
  }),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
