import axios from "@/http/axios";

const getDefaultState = () => {
  return {
    combinedIndicators: undefined,
    stratCumulativeReturns: undefined,
    benchmarkCumulativeReturns: undefined,
    performanceStats: undefined,
    outperformance: undefined,
    exposureTargetWeight: undefined,
    actions: undefined,
  };
};
// initial state
const state = { taaData: getDefaultState() };

const getters = {
  getCombinedIndicators: (state) => {
    return state.taaData.combinedIndicators;
  },
  getStratCumulativeReturns: (state) => {
    return state.taaData.stratCumulativeReturns;
  },
  getBenchmarkCumulativeReturns: (state) => {
    return state.taaData.benchmarkCumulativeReturns;
  },
  getPerformanceStats: (state) => {
    return state.taaData.performanceStats;
  },
  getOutperformance: (state) => {
    return state.taaData.outperformance;
  },
  getExposureTargetWeight: (state) => {
    return state.taaData.exposureTargetWeight;
  },
  getActions: (state) => {
    return state.taaData.actions;
  },
};

const mutations = {
  RESET_TAA_DATA_STATE(state) {
    state.taaData = getDefaultState();
  },
  FETCH_COMBINED_INDICATORS_SUCCESS: (state, { data }) => {
    state.taaData.combinedIndicators = data;
  },
  FETCH_STRAT_CUMULATIVE_RETURNS_SUCCESS: (state, { data }) => {
    state.taaData.stratCumulativeReturns = data;
  },
  FETCH_BENCHMARK_CUMULATIVE_RETURNS_SUCCESS: (state, { data }) => {
    state.taaData.benchmarkCumulativeReturns = data;
  },
  FETCH_PERFORMANCE_STATS_SUCCESS: (state, { data }) => {
    state.taaData.performanceStats = data;
  },
  FETCH_OUTPERFORMANCE_SUCCESS: (state, { data }) => {
    state.taaData.outperformance = data;
  },
  FETCH_EXPOSURE_TARGET_WEIGHT_SUCCESS: (state, { data }) => {
    state.taaData.exposureTargetWeight = data;
  },
  FETCH_ACTIONS_SUCCESS: (state, { data }) => {
    state.taaData.actions = data;
  },
};

const actions = {
  resetTaaDataState({ commit }) {
    commit("RESET_TAA_DATA_STATE");
  },
  launchBacktest(
    { commit },
    {
      startDate,
      endDate,
      currency,
      rebalancingThreshold,
      frequency,
      mode,
      indicators,
      portfolioId,
      txCost,
    }
  ) {
    return axios
      .post(
        "/api/indicators/tools/tactical-asset-allocation/backtest",
        {
          start_date: startDate,
          end_date: endDate,
          currency: currency,
          rebalancing_threshold: rebalancingThreshold,
          frequency,
          mode,
          indicators: indicators.map((indicator) => ({
            id: indicator.id,
            weight: indicator.weight / 100,
          })),
          portfolio_id: portfolioId,
          tx_cost: txCost,
        },

        {}
      )
      .then((response) => {
        commit("FETCH_COMBINED_INDICATORS_SUCCESS", {
          data: response.data["combined_indicator"],
        });
        commit("FETCH_STRAT_CUMULATIVE_RETURNS_SUCCESS", {
          data: response.data["our_cum_returns"],
        });
        commit("FETCH_BENCHMARK_CUMULATIVE_RETURNS_SUCCESS", {
          data: response.data["saa_cum_returns"],
        });
        commit("FETCH_PERFORMANCE_STATS_SUCCESS", {
          data: response.data["perf_stats"],
        });
        commit("FETCH_OUTPERFORMANCE_SUCCESS", {
          data: response.data["outperformance"],
        });
        commit("FETCH_EXPOSURE_TARGET_WEIGHT_SUCCESS", {
          data: response.data["exposure_target_weight"],
        });
        commit("FETCH_ACTIONS_SUCCESS", {
          data: response.data["actions"].reverse().map((actions, index) => ({
            id: index,
            color: "primary",
            icon: "RefreshCwIcon",
            title: "Rebalancing",
            time: new Date(Date.parse(actions["date"])).toLocaleString(
              "default",
              {
                year: "numeric",
                month: "short",
                day: "2-digit",
              }
            ),
            positions: Object.entries(actions["weights"]).map((action) => ({
              name: action[0],
              weight: action[1],
            })),
          })),
        });
      })
      .catch((error) => {
        commit("FETCH_COMBINED_INDICATORS_SUCCESS", {
          data: null,
        });
        commit("FETCH_STRAT_CUMULATIVE_RETURNS_SUCCESS", {
          data: null,
        });
        commit("FETCH_BENCHMARK_CUMULATIVE_RETURNS_SUCCESS", {
          data: null,
        });
        commit("FETCH_PERFORMANCE_STATS_SUCCESS", {
          data: null,
        });
        commit("FETCH_OUTPERFORMANCE_SUCCESS", {
          data: null,
        });
        commit("FETCH_EXPOSURE_TARGET_WEIGHT_SUCCESS", {
          data: null,
        });
        commit("FETCH_ACTIONS_SUCCESS", {
          data: null,
        });

        // We rethrow the error for sentry to catch it.
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
